.marker-inner {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    padding-left: 1px;
}

.marker-inner-center-text {
    position: absolute;
    color:white;
    font-size: 0.7rem;
    width: 100%;
    margin-top: 10px;
    /* font-family: Consolas; */
    font-weight: bold;
    font-size: 12px;
}


.marker-inner-center-circle {
    position: absolute;
    color:black;
    /* font-size: 0.7rem; */
    /* width: 100%; */
    margin-top: 8px;

    margin-left: 7px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.9);
    border: 1px black;
}

.marker-inner-center-circle-text {
    position: absolute;
    color:black;
    font-size: 0.7rem;
    width: 100%;
    margin-top: 3px;
    /* font-family: Consolas; */
    font-weight: bold;
    font-size: 11px;
}

.marker-inner-center-icon {
    position: absolute;
    width: 100%;
}

.marker-inner-icon-left {
    /* width: 100%; */
    margin-top: 0px;
    /* padding-top: 1px; */
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #FFFFFF;
    position: absolute;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.9);
    border: 1px black;
}

.marker-inner-icon-right {
    right: 0;
    margin-top: 0px;
    /* padding-top: 1px; */
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #FFFFFF;
    position: absolute;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.9);
    border: 1px black;

}

.marker-inner-icon-center-bottom {
    right: 10px;
    margin-top: 27px;
    /* padding-top: 1px; */
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #FFFFFF;
    position: absolute;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.9);
    border: 1px black;

}


.marker-cluster-div {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: #FFFFFF;
    position: absolute;
    /* transform: rotate(-45deg); */
    /* left: 50%; */
    top: 50%;
    box-shadow: 0px 0px 3px 2px rgba(0,0,0,1);
    /* opacity: 90%; */
    border: 2px black;
    /* transform: scaleX(1.5); */
    /* margin: -15px 0 0 -15px; */
}
/* .marker-pin::after {
    content: '';
    width: 20px;
    height: 20px;
    margin: 5px 0 0 5px;
    background: #ffeebf;
    position: absolute;
    border-radius: 50%;

} */



.marker-cluster-icon {
    position: absolute;
    width: 20px;
    /* font-size: 20px; */
    left: 0;
    right: 0;
    top: 50%;
    margin: 4px 0 0 5px;
    /* margin: 3px auto; */
    text-align: center;
    /* font-family: 'Consolas'; */
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    color: white;
}

.marker-cluster-text {
    position: absolute;
    width: 20px;
    /* font-size: 20px; */
    left: 0;
    right: 0;
    top: 50%;
    margin: 1px 0 0 3px;
    /* margin: 3px auto; */
    text-align: center;
    /* font-family: 'Consolas'; */
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    color: white;
    text-shadow: 
    -1px -1px 0px #000,
     0px -1px 0px #000,
     1px -1px 0px #000,
    -1px  0px 0px #000,
     1px  0px 0px #000,
    -1px  1px 0px #000,
     0px  1px 0px #000,
     1px  1px 0px #000;
}


.vehicle-div {
    width: 34px;
    height: 34px;
    /* border-radius: 0 50% 50% 50%; */
    /* background: rgb(15, 71, 138);; */
    position: absolute;
    /* transform: rotate(122deg); */
    /* left: 50%; */
    top: 50%;
    box-shadow: 0px 0px 3px 1px #fff;
    /* opacity: 90%; */
    border: 5px double white;
    /* transform: scaleX(1.5); */
    /* margin: -15px 0 0 -15px; */
    z-index: 1000;
}

.vehicle-div-icon {
    /* position: absolute; */
    /* width: 20px; */
    /* font-size: 20px; */
    /* left: 0; */
    /* right: 0; */
    /* top: 50%; */
    /* margin: 4px 0 0 5px; */
    /* margin: 3px auto; */
    /* text-align: center;
    align-items: center; */
    /* font-family: 'Consolas';
    font-size: 14px;
    font-weight: bold; */
    /* font-style: normal; */
    color: white;
    width: auto;
    z-index: 2000 !important;
    

}

.vehicle-text {
    position: absolute;
    flex: 1;
    top: 0;
    left: 50%;
    text-align: center;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    font-style: normal;
    transform: translateX(-50%);
    /* margin-top: 2px; */
    /* margin: 4px; */
    padding: 1px;
    text-shadow: 
    -1px -1px 0px #fff,
     0px -1px 0px #fff,
     1px -1px 0px #fff,
    -1px  0px 0px #fff,
     1px  0px 0px #fff,
    -1px  1px 0px #fff,
     0px  1px 0px #fff,
     1px  1px 0px #fff;
    /* text-shadow: 
        1px 0 0 #fff,
        -1px 0 0 #fff,
        0 1px 0 #fff,
        0 -1px 0 #fff; */
    /* text-shadow: .6px .6px 0 #fff; */
    /* color: #0f478a; */
    /* font-family: "Helvetica Neue", Arial, Helvetica, sans-serif; */
    /* font-family: 'Consolas'; */
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 2px rgba(255, 255, 255, 1);
    z-index: 2001 !important;
}

.fa-pulse { 
    /* border: 3px solid #999; */
    -webkit-border-radius: 30px;
    border-radius: 30px;
    height: 20px;
    width: 20px;       
    -webkit-animation: pulsate 1.5s ease-out;
    animation: pulsate 1.5s ease-out;
    -webkit-animation-iteration-count: infinite; 
    animation-iteration-count: infinite; 
    /*opacity: 0.0*/
}

@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}
@keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 1.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}


[mytitle] {
    cursor: pointer;
}

[mytitle]:after {
    content: '';
    transition: 0.2s;
}


[mytitle]:hover:after {
    width: fit-content;
    height: 20px;
    cursor: text;
    display: block;
    content: attr(mytitle);
    background-color: white;
    padding: 2px;
    z-index: 700;
    border-radius: 3px;
    background: #FFFFFF;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.9);
    border: 1px black;
}


#svg-cog {
    max-width:300px;
    animation: rotate linear infinite 5s; ;
    -webkit-animation:rotate linear infinite 5s;
}
  
@-webkit-keyframes rotate{
from{
    -webkit-transform: rotate(0deg);
}
to{
    -webkit-transform: rotate(360deg);
}
}

@keyframes rotate{
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
    }

.shadow {
    -webkit-filter: drop-shadow( 2px 1px 1px rgba(0, 0, 0, .7));
    filter: drop-shadow( 2px 2px 1px rgba(0, 0, 0, .7));
    /* Similar syntax to box-shadow */
    }

.shadow1 {
    -webkit-filter: drop-shadow( 2px -2px 1px rgba(0, 0, 0, .7));
    filter: drop-shadow( 2px -1px 1px rgba(0, 0, 0, .7));
    /* Similar syntax to box-shadow */
}
