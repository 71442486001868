.timeline_point {
    width: 12px; 
    height: 12px;
    background-color: #22B14C;
    border-radius: 50%;
    border: 1px solid black;
    
}

.timeline_point_active {
    width: 12px; 
    height: 12px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid black;
}

.timeline_point_black {
    width: 12px; 
    height: 12px;
    background-color: black;
    border-radius: 50%;
    border: 1px solid black;
}

.timeline_point:hover {
    width: 12px; 
    height: 12px;
    background-color: red;
    border-radius: 50%;
    border: 1px solid black;
}