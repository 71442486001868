.leaflet-popup-content {
    /* transform: scale(0.8); */
    max-width: 601px;
    margin: 10px 15px 10px 15px;
    font-size: 12px;
    line-height: 1.2;

}

.popup_header {
    /* background-color: black; */
    color: black;
    border-radius: 3px;
    font-size: 14px;
    /* font-family: 'Consolas'; */
    padding: 5px;
    display: flex;
    justify-content: center;
    text-align: center;
    transform: scale(1, 1.03);
    
}



.popup_body {
    display: flex;
    justify-content: center;
    /* font-size: 13px; */
}

.popup_body_badge {
    margin-left: 2px;
    margin-right: 2px;
    /* margin-top: 1px;  */
    /* margin-bottom: 1px; */
     /* border-radius: 5px;  */
    padding-left: 3px;
    padding-right: 3px;
    /* padding-top: 2px;
    padding-bottom: 1px; */
    /* font-family: 'Consolas'; */
    font-weight: bold;
    transform: scale(1, 1.03);
}


.popup_body_badge_coordinates {
    color: navy;
    white-space: nowrap;
}
.popup_body_badge_date{
    color: brown;
}

.popup_body_badge_fixations {
    color: green;
}

.popup_body_badge_violations {
    color: red;
}

.popup_body_badge_alerts_good{
    color: green;

}

.popup_body_badge_alerts_host {
    padding-left: 0px;
    padding-right: 5px;
    font-weight: bold;
    /* font-style: italic; */
    text-decoration: underline;
    color: black;
}

.popup_body_badge_alerts_problem {
    padding-left: 0px;
    padding-right: 0px;
    font-weight: bold;
    color: rgb(255, 0, 0);
}

.popup_body_badge_alerts_duration {
    color: rgb(0, 132, 255);
}

.popup_footer {
    display: flex;
    justify-content: space-between;
}

.popup_hr {
    margin-top: 7px;
    margin-bottom: 7px;
    opacity: 0.3;
}

.popup_hr_line {
    opacity: 0.4;
    color: 'black';
}

.popup_hr_text {
    margin-left: 5px;
    margin-right: 5px;
    opacity: 0.6;
    color: 'black';
    opacity: 0.6;
    /* font-family: 'Consolas'; */
}

.popup_svg {
    width: 14px;
    height: 14px;
}

@media screen and (max-width: 768px) {
    .leaflet-popup-content {
        /* transform: scale(0.8); */
        width: 301px;
        /* margin: 6px 12px 6px 10px; */
        font-size: 10px;
        line-height: 1;
    }

    .popup_header {
        font-size: 11px;
    }

    .popup_hr {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .popup_svg {
        width: 12px;
        height: 12px;
    }
}