.navbar {
    --bs-navbar-padding-x: 0;
    /* --bs-navbar-padding-y: 0; */
    --bs-navbar-brand-padding-y: 0;
}



@media screen and (max-width: 768px) {
    .navbar{
        --bs-navbar-padding-x: 0;
        --bs-navbar-padding-y: 0;
        /* padding: 0; */
    }
    .navbar-brand, .navbar-toggler {
        /* border:1px solid lightgray!important; */
        transform: scale(0.8);
        
    }
}

/* @media screen and (orientation: landscape) {
    .navbar{
        --bs-navbar-padding-x: 0;
        --bs-navbar-padding-y: 0;
    }
    .navbar-brand, .navbar-toggler {
        transform: scale(0.8);
        --bs-navbar-padding-y: 0;
        padding: 0;
    }
} */
