.modal {
    --bs-modal-header-padding: 5px 5px;
    /* font-family: Consolas; */
    /* padding: 5px; */
    /* transform: scale(1, 1.03); */
}

/* .modal-content {

} */

.modal_stat_header {
    justify-content: center;
    font-size: 14px;
}

.badge {
    font-size: 12px;
}

.tab {
    font-size: 12px;
}


.input-start {
    color: brown;
    background-color: white;
}
.input-end {
    color: brown;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

}
.text-left-start {
    /* background-color: lightgray;
    color: black;
    border: 1px solid black;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; */
    padding-right: 10px;
    padding-left: 10px;
}
.text-beetwen {
    /* background-color: lightgray;
    color: black;
    border: 1px solid black; */
    padding-right: 10px;
    padding-left: 10px;
}

@media screen and (max-width: 768px) {
    .modal_stat_header {
        justify-content: center;
        font-size: 10px;
    }
    
    .badge {
        font-size: 10px;
    }

    .tab {
        font-size: 9px;
    }
    

}
