.modal {
    --bs-modal-margin: 1rem !important;
}

.modal-content {
    font-size: 16px;
    /* font-family: Consolas; */
    transform: scale(1, 1.03);
    height: 95%;
}

.accordion {
    --bs-accordion-body-padding-x: 10px;
    --bs-accordion-body-padding-y: 10px;
    --bs-accordion-btn-padding-y: 5px;
    --bs-accordion-btn-padding-x: 15px;
    /* font-family: Consolas; */
}
.accordion-button {
    text-align: center;
    display: block;
}

.nav-link {
    --bs-nav-link-padding-y: 4px;
    --bs-nav-link-padding-x: 8px;
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    .modal-content {
        font-size: 10px;
        /* font-family: Consolas; */
        transform: scale(1, 1.03);
    }

   .accordion-button {
    text-align: center;
    display: block;
    font-size: 12px;
}

.nav-link {
    --bs-nav-link-padding-y: 3px;
    --bs-nav-link-padding-x: 6px;
    font-size: 11px;
}

}

