.modal {
    --bs-modal-header-padding: 5px 5px;
}

/* .modal-content {
    font-family: Consolas;
    transform: scale(1, 1.03);
} */

.modal_search_header {
    justify-content: center;
}

.modal_search_header_title {
    padding: 5px;
    font-size: 18px;

}

.modal_search_accordion_body {
    font-size: 14px;
    padding: 5px;
}

.modal_search_body {
    overflow-y: auto;
    height: calc(100vh - 250px);
}


.violations-span {
    color: red;
}

.fixations-span {
    color: green;
}

.modal_search_icon {
    width: 15px;
    height: 15px;
}

@media screen and (max-width: 768px) {
    .modal_search_header_title {
        padding: 5px;
        font-size: 14px;

    }

    .modal_search_accordion_body {
        font-size: 10px;
        padding: 3px;
    }

    .modal_search_icon {
        width: 12px;
        height: 12px;
    }

}