.leaflet-tooltip {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9) !important;
    border: 1px solid #000;
    color: #000;
    padding: 0px;
    /* width: 20px; */
}
.bat-4 {
    width: 40px;
    height: 14px;
    background: url('../assets/img/bat/bat-4.png');
    background-size: 40px;
}
.bat-3 {
    width: 40px;
    height: 14px;
    background: url('../assets/img/bat/bat-3.png');
    background-size: 40px;
}
.bat-2 {
    width: 40px;
    height: 14px;
    background: url('../assets/img/bat/bat-2.png');
    background-size: 40px;
}
.bat-1 {
    width: 40px;
    height: 14px;
    background: url('../assets/img/bat/bat-1.png');
    background-size: 40px;
}

