.trigger-list__item {
  color: red;
}

.params-list,
.trigger-list {
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.params-list__item,
.trigger-list__item {
  display: flex;
  justify-content: space-between;
}


.card-header {
  font-size: 14px;
}

.card-body {
  font-size: 12px;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 0;
  /* padding-right: 5px; */
  margin: 0;
  flex: none;
}


@media screen and (max-width: 768px) {
  .card-header {
    font-size: 10px;
  }

  .card-body {
    font-size: 8px;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
    padding-right: 0;
    /* padding-right: 5px; */
    margin: 0;
    flex: none;
  }
}