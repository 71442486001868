.leaflet-control {
    /* border:1px solid lightgray!important; */
    border-radius: 1px!important;
    /* font-family: 'Consolas'; */
    font-weight: bold;
}

.leaflet-control-layers  {
    border:1px solid black!important; 
    border-radius: 6px!important;
}

.leaflet-control-zoom {
    border:1px solid black!important; 
    border-radius: 4px!important;
}

.leaflet-control-zoom-in {
    width: 25px !important;
    height: 25px !important;
    line-height: 25px !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}
.leaflet-control-zoom-out {
    width: 25px !important;
    height: 25px !important;
    line-height: 25px !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.leaflet-control-layers-toggle {
    width: 35px !important;
    height: 35px !important;
    line-height: 35px !important;
}

.fa {
    padding-top: 2px;
    padding-left: 2px;
}

.fa-spin {
    padding: 0;
}

.btn {
    --bs-btn-padding-x: 2px;
    --bs-btn-padding-y: 2px;
    margin: 3px;
    font-size: 14px;
    /* font-family: Consolas; */
    font-weight: bold;
}

.btn_control {
    --bs-btn-padding-x: 6px;
    --bs-btn-padding-y: 6px;
    margin: 6px;
    border: 1px solid black;
}

.dropdown-menu {
    --bs-dropdown-min-width: 5rem;
    --bs-dropdown-padding-y: 0.1rem;
    --bs-dropdown-item-padding-x: 0.4rem;
    --bs-dropdown-item-padding-y: 0.2rem;
    --bs-dropdown-font-size: 13px;
}

.control_icon {
    width: 18px;
    height: 18px;
}


@media screen and (max-width: 768px) {
    .leaflet-control {
        /* border:1px solid lightgray!important; */
        transform: scale(0.8);
        /* margin-left: 2px; */
    }
    .leaflet-left .leaflet-control {
        margin-left: 2px;
    }

    .leaflet-top .leaflet-control {
        margin-top: 0px;
    }

    .leaflet-right .leaflet-control {
        margin-right: 0px;
    }

    .leaflet-bottom .leaflet-control {
        margin-bottom: 3px;
    }

    .btn {
        --bs-btn-padding-x: 3px;
        --bs-btn-padding-y: 3px;
        margin: 3px;
        font-size: 10px;
        /* font-family: Consolas; */
        font-weight: bold;
    }
    
    .btn_control {
        --bs-btn-padding-x: 6px;
        --bs-btn-padding-y: 6px;
        font-size: 14px;
        margin: 7px;
        border: 1px solid black;
    }

    .control_icon {
        width: 14px;
        height: 14px;
    }
}

/* @media screen and (orientation: landscape) {
    .leaflet-control {
        transform: scale(0.8);
    }
    .leaflet-left .leaflet-control {
        margin-left: 2px;
    }

    .leaflet-top .leaflet-control {
        margin-top: 0px;
    }

    .leaflet-right .leaflet-control {
        margin-right: 0px;
    }

    .leaflet-bottom .leaflet-control {
        margin-bottom: 3px;
    }
} */


