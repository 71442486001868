.modal-content {
    overflow: visible !important;
    position: relative;
}

.modal_info_header {
    justify-content: center;
}

.modal_info_header_title {
     padding: 5px;
     font-size: 18px;
   
} 

.modal_info_body {
    overflow-y: auto;
    height: calc(100vh - 300px);
    /* overflow: visible !important; */
}

.modal_info_body_verflow {
    overflow-y: auto;
    height: calc(100vh - 300px);
    overflow: visible !important;
}


.modal_info_images_form_select {
    width: 50%;
    font-size: 14px;
}


@media screen and (max-width: 768px) {
    .modal_info_header_title {
        padding: 5px;
        font-size: 13px;
      
   } 
   .modal_info_images_form_select {
        width: 50%;
        font-size: 10px;
   }

}

