:root {
  font-family: Consolas, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;  ;
  --bs-font-sans-serif: Consolas, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.App {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* font-family: Consolas !important; */
  }


.leaflet-container {
  width: 100%;
  height: calc(100vh - 52px);
  /* font-family: Consolas; */
  font-family: Consolas, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;  ;
  
}

@media screen and (max-width: 768px) {
  .leaflet-container {
    width: 100%;
    height: calc(100vh - 125px);
  }
}

/* @media screen and (orientation: landscape) {
  .leaflet-container {
    width: 100%;
    height: calc(100vh - 100px);
  }
} */
  
  /* .leaflet-popup-content-wrapper {
    background-color: rgba(255, 255, 255, 0)!important;
    box-shadow: 0 0;
    padding: -20 -20!important;
  }

  .leaflet-popup-content {
    
  } */

/* .disc-rli-bounding-box {
  position: absolute;
  left: 50%;
  top: 50%;

} */

.riple-rli-bounding-box {
  position: absolute;
  left: 50%;
  top: 50%;

}